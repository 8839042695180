<template>
  <component :is="projectData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No Nomenclature found -->
    <b-alert variant="danger" :show="projectData === undefined">
      <h4 class="alert-heading">Erreur lors de chargement du contenu</h4>
      <div class="alert-body">
        Aucun element trouvé avec cet identifiant. Vérifier
        <b-link class="alert-link" :to="{ name: 'project-list' }">
          Liste des elements
        </b-link>
        pour les autres element.
      </div>
    </b-alert>

    <data-add-tab
      :project-data="projectData"
      :stepformblank-data="stepformblankData"
      :itemformblank-data="itemformblankData"
      class="mt-2"
    />
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import storeModule from "./storeModule";
import DataAddTab from "./FormData.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormCheckbox,
    DataAddTab,
    BButton,
    // DataAddTabInformation,
    // DataAddTabSocial,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
    };
  },
  mounted() {
    if (router.currentRoute.name == "project-add-new") {
      this.operationTitle = "Nouvelle Nomenclature";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "project-view") {
      this.operationTitle = "Détails de la Nomenclature";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "project-edit") {
      this.operationTitle = "Modification de la project";
      this.operationIcon = "EditIcon";
    }
  },
  methods: {
    prevPage() {
      router.push({ name: "project-list" });
    },
    convertBoolToInt(value) {
      if (value == 1) {
        return true;
      } else {
        return false;
      }
    },
  },
  setup() {
    let projectData = ref(null);

    var itemformblankData = ref({
      name: "",
      cost: 0,
      qty: 1,
      qty_unit: "",
      description: "",
    });

    var stepformblankData = ref({
      name: "",
      items: [itemformblankData],
      time: 0,
      time_unit: "",
      description: "",
    });

    let APP_STORE_MODULE_NAME = "app-project";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    var defaultprojectData = ref({
      // id: 5037,
      setting: {
        name: "Parametres",
        description: "Définir la configuration globale",
        data: {},
      },
      attachments: {
        name: "Pieces Jointes",
        description: "Documents liés au projet",
        data: {},
      },
      organisation: {
        name: "A) Informations sur l'organisation",
        description: "Contacts et autres informations de l'organisation",
        data: {},
        attachments: {},
      },
      contactperson: {
        name: "B) Personne à contacter dans l’organisation",
        description: "",
        data: {},
        attachments: {},
      },
      contactperson2: {
        name: "C) Personne secondaire à contacter dans l’organisation",
        description: "",
        data: {},
        attachments: {},
      },
      projectmanager: { name: "D) Chef de Projet", description: "", data: {} },
      focalpointfinance: {
        name: "E) Point focal / financier à contacter dans l’organisation",
        description: "",
        data: {},
        attachments: {},
      },
      funding: {
        name: "1) Financement",
        description: "Informations sur le bailleur des fonds ",
        data: {},
        attachments: {},
      },
      resume: {
        name: "2) Résumé du Projet (1500 mots maximum)",
        description: "Synthèse du projet",
        data: {},
        attachments: {},
      },
      project: {
        name: "3) Détails du Projet",
        description: "Informations générales sur le projet.",
        data: {},
        attachments: {},
      },
      finance: {
        name: "Gestion Financière",
        description: "Informations financières de base.",
        data: {},
        attachments: {},
      },
      outcome: {
        name: "Outcome (Rés. Inter.)",
        description: "Résultats Intermediaires",
        data: [],
        output: {
          name: "Output (Rés. Imméd.)",
          description: "Résultats Immédiats",
          activity: {
            name: "Activités",
            description: "Les activités liés aux outputs",
            sub_activity: {
              name: "Sous-Activités",
              description: "Les Sous-activités liés aux activités",
            },
          },
        },
        indicator: {},
        attachments: {},
      },
      indicator: {
        name: "Les Indicateurs du But",
        description: "Indicateurs de niveau 1",
        data: {},
        attachments: {},
      },
      budget: {
        name: "Suivi du Budget",
        description:
          "Répartition et suivi du budget par activité de la période",
        items: {},
        attachments: {},
      },
      repport: {
        name: "Rapportage",
        description: "Gestion du rapportage.",
        items: {},
        attachments: {},
      },
      managementtool: {
        name: "Outils de Gestion",
        description: "Formulaires vides, canevas,...",
        data: {},
        attachments: {},
      },
      name: "",
      type: "",
      description: "",
      // ? Set single Item in form for adding data
      steps: {},
    });
    // JSON.parse(JSON.stringify(itemformblankData))
    if (router.currentRoute.name == "project-add-new") {
      // init empty project data
      projectData = defaultprojectData;
    }
    else{
     store
          .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
            id: router.currentRoute.params.id,
          })
       .then((response) => { 
            
         var data_to_update = response.data;

         console.log('data_to_update')
         console.log(data_to_update)

         data_to_update.setting = JSON.parse(data_to_update.setting); 
           

            projectData = data_to_update;
             
          })
       .catch((error) => {
            console.log(error)
            if (error.response.status === 404) {
              projectData.value = undefined;
            }
          });
    }

    return {
      projectData,
      itemformblankData,
      stepformblankData,
    };
  },
};
</script>
