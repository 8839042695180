<template>
  <div>
    <b-row>
      <b-col cols="8">
        <h6
          class="section-label text-primary float-left"
          style="
            border-right: 5px solid orange;
            border-bottom: 1px solid orange;
            border-radius: 0px 50px 0px 0px;
            font-size: 18px;
            padding-right: 3px;
            top: 1px;
            width: auto;
          "
        >
          <feather-icon icon="FileAddIcon" size="25" />
          {{ operationTitle }} Projet
        </h6>
      </b-col>
      <b-col cols="4">
        <b-button
          v-if="$router.name == 'project-view'"
          variant="outline-primary"
          class="btn-sm float-right"
          @click="
            $router.push({
              name: 'project-view',
              params: { id: projectData.id + 1 },
            })
          "
        >
          <span class="d-none d-sm-inline">Suiv.</span>
          <feather-icon icon="ChevronRightIcon" size="12" />
        </b-button>
        <b-button
          v-if="$router.name == 'project-view'"
          variant="outline-primary"
          class="btn-sm float-right"
          @click="
            $router.push({
              name: 'project-view',
              params: { id: projectData.id - 1 },
            })
          "
        >
          <feather-icon icon="ChevronLeftIcon" size="12" />
          <span class="d-none d-sm-inline">Préc.</span>
        </b-button>

        <b-button
          variant="outline-primary"
          class="btn-sm float-right"
          @click="
            $router.push({
              name: 'project-list',
            })
          "
        >
          <feather-icon icon="ListIcon" size="12" />
          <span class="d-none d-sm-inline">Liste des Projects</span>
        </b-button>
      </b-col>
    </b-row>

    <form-wizard
      color="#2d73b5"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Enregistrer"
      back-button-text="Précédent"
      next-button-text="Suivant"
      class="wizard-vertical mb-3"
      @on-complete="addUpdateItemAction"
    >
      <!-- Settings tab -->
      <tab-content :title="projectData.setting.name">
        <b-form
          @submit.prevent="handleSubmit(addUpdateItemAction)"
          @reset.prevent="resetForm"
          v-if="projectData != null"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ projectData.setting.name }}</h5>
              <small class="text-muted">{{
                projectData.setting.description
              }}</small>
            </b-col>

            <b-col md="12" class="mb-2">
              <h5 class="mb-0 text-info">Subdivision du temps</h5>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="validationContext"
                name="project.data.annualsectionunit"
                rules="required"
              >
                <b-form-group
                  label="Temps d'Exécution"
                  label-for="project.data.executiontimetype"
                >
                  <v-select
                    id="project.data.executiontimetype"
                    v-model="projectData.project.data.executiontimetype"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="executiontimetypeUnit"
                    :state="getValidationState(validationContext)"
                    label="text"
                    size="sm"
                    class="select-size-sm"
                    v-on:change="
                      executiontimetypeChange(
                        projectData.project.data.executiontimetype
                      )
                    "
                  />
                  <b-form-invalid-feedback>
                    Completer Type de Temps d'Exécution s'il vous plait
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="validationContext"
                name="project.data.annualsubdivision"
                rules="required"
              >
                <b-form-group
                  label="Unité de subdivision annuelle"
                  label-for="project.data.annualsubdivision"
                >
                  <v-select
                    id="project.data.annualsubdivision"
                    v-model="projectData.project.data.annualsubdivision"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="annualsubdivisionUnit"
                    :state="getValidationState(validationContext)"
                    label="text"
                    size="sm"
                    class="select-size-sm"
                    v-on:change="
                      annualsubdivisionChange(
                        projectData.project.data.annualsubdivision
                      )
                    "
                  />
                  <b-form-invalid-feedback>
                    Completer Unité de subdivision annuelle s'il vous plait
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="3">
              <validation-provider
                #default="validationContext"
                name="project.data.workplantiming"
                rules="required"
              >
                <b-form-group
                  label="Plan de travail / suivi continue"
                  label-for="project.data.workplantiming"
                >
                  <v-select
                    id="project.data.workplan"
                    v-model="projectData.project.data.workplantiming"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="workplantimingUnit"
                    :state="getValidationState(validationContext)"
                    label="text"
                    size="sm"
                    class="select-size-sm"
                    v-on:change="
                      workplantimingChange(
                        projectData.project.data.workplantiming
                      )
                    "
                  />
                  <b-form-invalid-feedback>
                    Completer Plan de travail / suivi continue svp
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <hr class="invoice-spacing" />

            <b-col md="12" class="mb-2 mt-10">
              <h5 class="mb-0 text-info">
                Parametres de l'accordéon (caché/affiché) du contenu long
              </h5>
            </b-col>
            <b-col md="12" class="mb-2">
              <b-form-radio-group
                id="radio-group-2"
                v-model="collapseType"
                name="radio-sub-component"
              >
                <b-form-radio value="default"> Normal </b-form-radio>
                <b-form-radio value="shadow"> Obragé </b-form-radio>
                <b-form-radio value="margin"> Séparé </b-form-radio>
                <b-form-radio value="border"> Bordure </b-form-radio>
              </b-form-radio-group>
            </b-col>
            <hr class="invoice-spacing" />
            <b-col md="12" class="mb-2 mt-10">
              <h5 class="mb-0 text-info">Parametres des préfixes du codage</h5>
            </b-col>
            <b-col md="3">
              <b-form-group label="Année" label-for="projectData.prefix.year">
                <b-form-input
                  id="projectData.prefix.year"
                  v-model="projectData.prefix.year"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Semestre" label-for="projectData.prefix.semester">
                <b-form-input
                  id="projectData.prefix.semester"
                  v-model="projectData.prefix.semester"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Trimestre" label-for="projectData.prefix.trimester">
                <b-form-input
                  id="projectData.prefix.trimester"
                  v-model="projectData.prefix.trimester"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Mois" label-for="projectData.prefix.month">
                <b-form-input
                  id="projectData.prefix.month"
                  v-model="projectData.prefix.month"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Résultat Intermédiaire" label-for="projectData.prefix.outcome">
                <b-form-input
                  id="projectData.prefix.outcome"
                  v-model="projectData.prefix.outcome"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Résultat Immédiat" label-for="projectData.prefix.output">
                <b-form-input
                  id="projectData.prefix.output"
                  v-model="projectData.prefix.output"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Activité" label-for="projectData.prefix.activity">
                <b-form-input
                  id="projectData.prefix.activity"
                  v-model="projectData.prefix.activity"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Sous-Activité" label-for="projectData.prefix.sub_activity">
                <b-form-input
                  id="projectData.prefix.sub_activity"
                  v-model="projectData.prefix.sub_activity"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="text"
                  size="sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </tab-content>

      <!-- Info. sur l’organisation tab -->
      <tab-content title="Organisation">
        <b-row>
          <b-form
            @submit.prevent="handleSubmit(addUpdateItemAction)"
            @reset.prevent="resetForm"
            v-if="projectData != null"
          >
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Organisation</h5>
              <small class="text-muted"
                >Contacts et autres informations de l'organisation</small
              >
            </b-col>

            <b-col cols="12" xl="12" md="12">
              <b-card no-body class="invoice-preview-card">
                <!-- Organistion -->
                <b-row class="">
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0 text-warning">
                      {{ projectData.organisation.name }}
                    </h5>
                  </b-col>
                  <!-- organisation name -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="organisation"
                      rules="required"
                    >
                      <b-form-group
                        label="Nom de l’organisation"
                        label-for="organisation"
                      >
                        <b-form-input
                          id="organisation"
                          v-model="projectData.organisation.data.name"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Dynamique des Femmes Juristes"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer Nom complet de l’organisation s'il vous
                          plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Initiales / Abreviation de l’organisation -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="abreviation"
                      rules="required"
                    >
                      <b-form-group
                        label="Initiales / Abreviation de l’organisation"
                        label-for="abreviation"
                      >
                        <b-form-input
                          id="abreviation"
                          v-model="projectData.organisation.data.abreviation"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: DFJ"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer Abreviation de l’organisation s'il vous
                          plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- adress de l’organisation -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="adress"
                      rules="required"
                    >
                      <b-form-group
                        label="Adresse de l’organisation"
                        label-for="adress"
                      >
                        <b-form-input
                          id="adress"
                          v-model="projectData.organisation.data.adress"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Goma, Nord Kivu, RDC"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer adresse de l’organisation s'il vous plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- town -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="town"
                      rules="required"
                    >
                      <b-form-group label="Ville " label-for="adress">
                        <b-form-input
                          id="adress"
                          v-model="projectData.organisation.data.adress"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Goma"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer ville s'il vous plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- state -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="state"
                      rules="required"
                    >
                      <b-form-group label="Province " label-for="state">
                        <b-form-input
                          id="state"
                          v-model="projectData.organisation.data.state"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Nord Kivu"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer province s'il vous plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- country -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="country"
                      rules="required"
                    >
                      <b-form-group label="Pays " label-for="country">
                        <b-form-input
                          id="country"
                          v-model="projectData.organisation.data.country"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: RDC"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer pays s'il vous plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- email -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="email"
                      rules="required"
                    >
                      <b-form-group label="Email" label-for="email">
                        <b-form-input
                          id="email"
                          v-model="projectData.organisation.data.email"
                          :state="getValidationState(validationContext)"
                          trim
                          type="email"
                          placeholder="Ex: info@dfj-drc.org"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer email s'il vous plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6">
                    <!-- website -->
                    <b-form-group label="Siteweb" label-for="website">
                      <b-form-input
                        v-model="projectData.organisation.data.website"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        type="text"
                        class="mb-2"
                        input-id="website"
                        size="sm"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <!-- phone -->
                    <validation-provider
                      #default="validationContext"
                      name="phone"
                      rules="required"
                    >
                      <b-form-group
                        label="Numéro(s) de téléphone"
                        label-for="phone"
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          id="phone"
                          v-model="projectData.organisation.data.phone"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: +243 99........."
                          size="sm"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          Saisissez numéro(s) de téléphone
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="6">
                    <!-- postalbox -->
                    <b-form-group label="Boite Postale" label-for="postalbox">
                      <b-form-input
                        v-model="projectData.organisation.data.postalbox"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        class="mb-2"
                        input-id="postalbox"
                        size="sm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Main Contact person -->
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0 text-success">
                      {{ projectData.contactperson.name }}
                    </h5>
                  </b-col>

                  <!-- contactpersontitle -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactpersontitle"
                      rules="required"
                    >
                      <b-form-group
                        label="Titre de la personne"
                        label-for="contactpersontitle"
                      >
                        <b-form-input
                          id="contactpersontitle"
                          v-model="projectData.contactperson.data.title"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Mme, M., Autre, He/she"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer Titre de la personne s'il vous plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- personne de contact -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactpersonfirstname"
                      rules="required"
                    >
                      <b-form-group
                        label="Prenom"
                        label-for="contactpersonfirstname"
                      >
                        <b-form-input
                          id="contactpersonfirstname"
                          v-model="projectData.contactperson.data.firstname"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Eliezer"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer prenom de la personne principale de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- contact person name -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactpersonname"
                      rules="required"
                    >
                      <b-form-group label="Nom" label-for="contactpersonname">
                        <b-form-input
                          id="contactpersonname"
                          v-model="projectData.contactperson.data.name"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Mbavughavyo"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer nom de la personne principale de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- function -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactpersonfunction"
                      rules="required"
                    >
                      <b-form-group
                        label="Fonction"
                        label-for="contactpersonfunction"
                      >
                        <b-form-input
                          id="contactpersonfunction"
                          v-model="projectData.contactperson.data.function"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Fx"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Fonction de la personne principale de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- contactpersonemail -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactpersonemail"
                      rules="required"
                    >
                      <b-form-group
                        label="Email"
                        label-for="contactpersonemail"
                      >
                        <b-form-input
                          id="contactpersonemail"
                          v-model="projectData.contactperson.data.email"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: contact.perso@dfj-drc.org"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer email de la personne principale de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- contactpersonphone -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactpersonphone"
                      rules="required"
                    >
                      <b-form-group
                        label="Téléphone"
                        label-for="contactpersonphone"
                      >
                        <b-form-input
                          id="contactpersonphone"
                          v-model="projectData.contactperson.data.phone"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: +242 99....."
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          N° téléphone de la personne principale de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Secondary Contact person -->
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0 text-danger">
                      {{ projectData.contactperson2.name }}
                    </h5>
                  </b-col>

                  <!-- contactperson2title -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactperson2title"
                      rules="required"
                    >
                      <b-form-group
                        label="Titre de la personne secondaire"
                        label-for="contactperson2title"
                      >
                        <b-form-input
                          id="contactperson2title"
                          v-model="projectData.contactperson2.data.title"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Mme, M., Autre, He/she"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer Titre de la personne secondaire s'il vous
                          plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- personne de contact -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactperson2firstname"
                      rules="required"
                    >
                      <b-form-group
                        label="Prenom"
                        label-for="contactperson2firstname"
                      >
                        <b-form-input
                          id="contactperson2firstname"
                          v-model="projectData.contactperson2.data.firstname"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Kahindo"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer prenom de la personne principale de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- contact person name -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactperson2name"
                      rules="required"
                    >
                      <b-form-group label="Nom" label-for="contactperson2name">
                        <b-form-input
                          id="contactperson2name"
                          v-model="projectData.contactperson2.data.name"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Olame M"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer nom de la personne seconaire de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- function -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactperson2function"
                      rules="required"
                    >
                      <b-form-group
                        label="Fonction"
                        label-for="contactperson2function"
                      >
                        <b-form-input
                          id="contactperson2function"
                          v-model="projectData.contactperson2.data.function"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Fx"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Fonction de la personne principale de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- contactperson2email -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactperson2email"
                      rules="required"
                    >
                      <b-form-group
                        label="Email"
                        label-for="contactperson2email"
                      >
                        <b-form-input
                          id="contactperson2email"
                          v-model="projectData.contactperson2.data.email"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: contact.perso.sec@dfj-drc.org"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer email de la personne secondaire de contact
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- contactperson2phone -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="contactperson2phone"
                      rules="required"
                    >
                      <b-form-group
                        label="Téléphone"
                        label-for="contactperson2phone"
                      >
                        <b-form-input
                          id="contactperson2phone"
                          v-model="projectData.contactperson2.data.phone"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: +242 99....."
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer n° téléphone de la personne secondaire
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- projectmanager -->
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0 text-purple">
                      {{ projectData.projectmanager.name }}
                    </h5>
                  </b-col>

                  <!-- projectmanagertitle -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="projectmanagertitle"
                      rules="required"
                    >
                      <b-form-group
                        label="Titre du chef de projet"
                        label-for="projectmanagertitle"
                      >
                        <b-form-input
                          id="projectmanagertitle"
                          v-model="projectData.projectmanager.data.title"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Mme, M., Autre, He/she"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer Titre du chef de projet s'il vous plait
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- projectmanager name -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="projectmanagerfirstname"
                      rules="required"
                    >
                      <b-form-group
                        label="Prenom"
                        label-for="projectmanagerfirstname"
                      >
                        <b-form-input
                          id="projectmanagerfirstname"
                          v-model="projectData.projectmanager.data.firstname"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Kahindo"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer prenom du chef de projet
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- projectmanager nom -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="projectmanagername"
                      rules="required"
                    >
                      <b-form-group label="Nom" label-for="projectmanagername">
                        <b-form-input
                          id="projectmanagername"
                          v-model="projectData.projectmanager.data.name"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Mbasuvyaki K"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer nom du chef de projet
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- function -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="projectmanagerfunction"
                      rules="required"
                    >
                      <b-form-group
                        label="Fonction"
                        label-for="projectmanagerfunction"
                      >
                        <b-form-input
                          id="projectmanagerfunction"
                          v-model="projectData.projectmanager.data.function"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Fx"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Fonction du chef de projet
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- projectmanageremail -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="projectmanageremail"
                      rules="required"
                    >
                      <b-form-group
                        label="Email"
                        label-for="projectmanageremail"
                      >
                        <b-form-input
                          id="projectmanageremail"
                          v-model="projectData.projectmanager.data.email"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: contact.perso.sec@dfj-drc.org"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer email du chef de projet
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- projectmanagerphone -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="projectmanagerphone"
                      rules="required"
                    >
                      <b-form-group
                        label="Téléphone"
                        label-for="projectmanagerphone"
                      >
                        <b-form-input
                          id="projectmanagerphone"
                          v-model="projectData.projectmanager.data.phone"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: +242 99....."
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer n° téléphone du chef de projet
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Point focal -->
                <b-row>
                  <b-col cols="12" class="mb-2">
                    <h5 class="mb-0 text-primary">
                      {{ projectData.focalpointfinance.name }}
                    </h5>
                  </b-col>

                  <!-- focalpointfinancetitle -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="focalpointfinancetitle"
                      rules="required"
                    >
                      <b-form-group
                        label="Titre de la personne secondaire"
                        label-for="focalpointfinancetitle"
                      >
                        <b-form-input
                          id="focalpointfinancetitle"
                          v-model="projectData.focalpointfinance.data.title"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Mme, M., Autre, He/she"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer Titre du point focal financier svp
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- focalpointfinance name -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="focalpointfinancefirstname"
                      rules="required"
                    >
                      <b-form-group
                        label="Prenom"
                        label-for="focalpointfinancefirstname"
                      >
                        <b-form-input
                          id="focalpointfinancefirstname"
                          v-model="projectData.contactperson2.data.firstname"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Mumbere Mumathe"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer prenom du point focal/financier
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- contact person name -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="focalpointfinancename"
                      rules="required"
                    >
                      <b-form-group
                        label="Email"
                        label-for="focalpointfinancename"
                      >
                        <b-form-input
                          id="focalpointfinancename"
                          v-model="projectData.focalpointfinance.data.name"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Ezra K"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer nom du point focal/financier
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- focalpointfinance function -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="focalpointfinancefunction"
                      rules="required"
                    >
                      <b-form-group
                        label="Fonction"
                        label-for="focalpointfinancefunction"
                      >
                        <b-form-input
                          id="focalpointfinancefunction"
                          v-model="projectData.focalpointfinance.data.function"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: Fx"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Fonction du point focal/financier svp.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- focalpointfinanceemail -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="focalpointfinanceemail"
                      rules="required"
                    >
                      <b-form-group
                        label="Email"
                        label-for="focalpointfinanceemail"
                      >
                        <b-form-input
                          id="focalpointfinanceemail"
                          v-model="projectData.focalpointfinance.data.email"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: finance.focal.sec@dfj-drc.org"
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer email du point focal/financier
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- focalpointfinancephone -->
                  <b-col cols="12" md="6">
                    <validation-provider
                      #default="validationContext"
                      name="focalpointfinancephone"
                      rules="required"
                    >
                      <b-form-group
                        label="Téléphone"
                        label-for="focalpointfinancephone"
                      >
                        <b-form-input
                          id="focalpointfinancephone"
                          v-model="projectData.focalpointfinance.data.phone"
                          :state="getValidationState(validationContext)"
                          trim
                          placeholder="Ex: +242 99....."
                          size="sm"
                        />
                        <b-form-invalid-feedback>
                          Completer n° téléphone du point focal/financier
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <!-- Spacer -->
                <!-- <hr class="invoice-spacing" v-if="showed == true" /> -->
              </b-card>
            </b-col>

            <!-- <b-row style="margin-bottom: 20px" v-if="showed == true">
                <b-col cols="12">
                  <b-button
                    ref="refSubmitEl"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      v-if="loading == false"
                      size="19"
                    />

                    {{ actionName }}
                  </b-button>
                  <b-button
                    variant="outline-secondary"
                    type="reset"
                    @click="reinitForm"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  >
                    Annuler
                  </b-button>
                </b-col>
              </b-row> -->
          </b-form>
        </b-row>
      </tab-content>

      <!-- Détails du Projet -->
      <tab-content title="Détails du Projet">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-success">{{ projectData.funding.name }}</h5>
            <small class="">{{ projectData.funding.description }}</small>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="funding.data.donor"
              rules="required"
            >
              <b-form-group
                label="Bailleur des fonds"
                label-for="funding.data.donor"
              >
                <b-form-input
                  id="funding.data.donor"
                  v-model="projectData.funding.data.donor"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: USAID,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Bailleur des fonds s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="funding.data.website"
              rules="required"
            >
              <b-form-group label="Site web" label-for="funding.data.website">
                <b-form-input
                  id="funding.data.website"
                  v-model="projectData.funding.data.website"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: https://www.usaid.gov,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Site web s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="funding.data.websiteprojectlink"
              rules="required"
            >
              <b-form-group
                label="Site web vers le projet "
                label-for="websiteprojectlink"
              >
                <b-form-input
                  id="funding.data.websiteprojectlink"
                  v-model="projectData.funding.data.websiteprojectlink"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: https://app.usaid.gov/projects/usai-200-250,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Site web vers le projet s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="funding.data.websiteprojectlinkforressource"
              rules="required"
            >
              <b-form-group
                label="Site web pour ressources (si applicable)"
                label-for="websiteprojectlinkforressource"
              >
                <b-form-input
                  id="funding.data.websiteprojectlinkforressource"
                  v-model="
                    projectData.funding.data.websiteprojectlinkforressource
                  "
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: https://app.usaid.gov/projects/usai-200-250/ress/001-23548,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Site web pour ressources (si applicable)
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="funding.data.portfoliomanager"
              rules="required"
            >
              <b-form-group
                label="Gestionnaire de portefeuille"
                label-for="portfoliomanager"
              >
                <b-form-input
                  id="funding.data.portfoliomanager"
                  v-model="projectData.funding.data.portfoliomanager"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: https://app.usaid.gov/projects/usai-200-250/ress/001-23548,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Gestionnaire de portefeuille svp
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="funding.data.financialfocalpoint"
              rules="required"
            >
              <b-form-group
                label="Point focal financier"
                label-for="financialfocalpoint"
              >
                <b-form-input
                  id="funding.data.financialfocalpoint"
                  v-model="projectData.funding.data.financialfocalpoint"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Johhan Reimer,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Point focal financier svp
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-danger">{{ projectData.resume.name }}</h5>
            <small class="">{{ projectData.resume.description }}</small>
          </b-col>

          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="resume.data.resusme"
              rules="required"
            >
              <b-form-group label="" label-for="resume.data.resume">
                <b-form-textarea
                  id="resume.data.resume"
                  v-model="projectData.resume.data.resusme"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Résumé du projet dans 1500 mots maximum,..."
                  rows="5"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer résumé du projet svp
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">{{ projectData.project.name }}</h5>
            <small class="text-warning">{{
              projectData.project.description
            }}</small>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.name"
              rules="required"
            >
              <b-form-group
                label="Titre/designation du projet"
                label-for="project.data.name"
              >
                <b-form-input
                  id="project.data.name"
                  v-model="projectData.project.data.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Mobilisation Feminine pour la Desinformation"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Titre/designation du projet s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.code"
              rules="required"
            >
              <b-form-group
                label="Code du projet"
                label-for="project.data.code"
              >
                <b-form-input
                  id="project.data.code"
                  v-model="projectData.project.data.code"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: USAID-A022-BETA"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Code du projet s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="12">
            <validation-provider
              #default="validationContext"
              name="project.data.goal"
              rules="required"
            >
              <b-form-group
                label="But principal du projet"
                label-for="project.data.goal"
              >
                <b-form-input
                  id="project.data.goal"
                  v-model="projectData.project.data.goal"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Rendre justice aux 9 200 000 femmes violées en RDC de 1994 à 2022"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer But principal du projet s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.projectduration"
              rules="required"
            >
              <b-form-group
                label="Durée du projet"
                label-for="project.data.projectduration"
              >
                <b-form-input
                  id="project.data.projectduration"
                  v-model="projectData.project.data.projectduration"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 3"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Durée du projet s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.projectdurationunit"
              rules="required"
            >
              <b-form-group
                label="Unité de durée du projet"
                label-for="project.data.projectdurationunit"
              >
                <v-select
                  id="project.data.projectdurationunit"
                  v-model="projectData.project.data.projectdurationunit"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationUnit"
                  :state="getValidationState(validationContext)"
                  label="text"
                  size="sm"
                  class="select-size-sm"
                  @change="
                    projectdurationunitChange(
                      projectData.project.data.projectdurationunit
                    )
                  "
                  @select="projectdurationunitChange('select')"
                  @search="projectdurationunitChange('search')"
                  @input="projectdurationunitChange('input')"
                />
                <b-form-invalid-feedback>
                  Completer Unité de durée projet s'il vous plait
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.datestart"
              rules="required"
            >
              <b-form-group
                label="Date Début Projet"
                label-for="project.data.datestart"
              >
                <b-form-datepicker
                  id="project.data.datestart"
                  v-model="projectData.project.data.datestart"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 30 Juin 1960"
                  class="mb-1"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Date de début
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.dateend"
              rules="required"
            >
              <b-form-group
                label="Date Fin Projet"
                label-for="project.data.dateend"
              >
                <b-form-datepicker
                  id="project.data.dateend"
                  v-model="projectData.project.data.dateend"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 30 Juin 2050"
                  class="mb-1"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Date de Clôture du Projet
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.geoscope"
              rules="required"
            >
              <b-form-group
                label="Portée géographique du projet"
                label-for="project.data.geoscope"
              >
                <b-form-input
                  id="project.data.geoscope"
                  v-model="projectData.project.data.geoscope"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Régional, sous-régional, national,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer la Portée géographique du projet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.countrycovered"
              rules="required"
            >
              <b-form-group
                label="Pays couverts par le projet"
                label-for="project.data.countrycovered"
              >
                <b-form-input
                  id="project.data.countrycovered"
                  v-model="projectData.project.data.countrycovered"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: RDC, Angola,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Pays couverts par le projet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.statecovered"
              rules="required"
            >
              <b-form-group
                label="Provinces couvertes par le projet"
                label-for="project.data.statecovered"
              >
                <b-form-input
                  id="project.data.statecovered"
                  v-model="projectData.project.data.statecovered"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Nord Kivu, Ituri,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Provinces couvertes par le projet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.territorycovered"
              rules="required"
            >
              <b-form-group
                label="Territoire couverts par le projet"
                label-for="project.data.territorycovered"
              >
                <b-form-input
                  id="project.data.territorycovered"
                  v-model="projectData.project.data.territorycovered"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: RDC, Angola,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Territoire couverts par le projet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.areacovered"
              rules="required"
            >
              <b-form-group
                label="Zones de santé couvertes par le projet"
                label-for="project.data.areacovered"
              >
                <b-form-input
                  id="project.data.areacovered"
                  v-model="projectData.project.data.areacovered"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Lubero, Irumu, Mambasa,..."
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Zones (de santé) couvertes par le projet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-info">4) Budget</h5>
            <small class="">Informations sur le budget</small>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.budgetamount"
              rules="required"
            >
              <b-form-group
                label="Montant total du budget du projet"
                label-for="project.data.budgetamount"
              >
                <b-form-input
                  id="project.data.budgetamount"
                  v-model="projectData.project.data.budgetamount"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 1 000 000"
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Montant total du budget du projet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.budgetamountcurrency"
              rules="required"
            >
              <b-form-group
                label="Devise du Montant total du budget du projet"
                label-for="project.data.budgetamountcurrency"
              >
                <b-form-input
                  id="project.data.budgetamountcurrency"
                  v-model="projectData.project.data.budgetamountcurrency"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: USD, CDF, GBL,..."
                  size="sm"
                  value="USD"
                />
                <b-form-invalid-feedback>
                  Completer Devise du Montant total du budget du projet svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.donorgrant"
              rules="required"
            >
              <b-form-group
                label="Subvention du bailleur des fonds"
                label-for="project.data.donorgrant"
              >
                <b-form-input
                  id="project.data.donorgrant"
                  v-model="projectData.project.data.donorgrant"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Subvention du bailleur des fonds svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.organisationcontribution"
              rules="required"
            >
              <b-form-group
                label="Contribution de la DFJ"
                label-for="project.data.organisationcontribution"
              >
                <b-form-input
                  id="project.data.organisationcontribution"
                  v-model="projectData.project.data.organisationcontribution"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Contribution de la DFJ svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.data.othercontribution"
              rules="required"
            >
              <b-form-group
                label="Autres contributions"
                label-for="project.data.othercontribution"
              >
                <b-form-input
                  id="project.data.othercontribution"
                  v-model="projectData.project.data.othercontribution"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Autres contributions svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-info">5) Gestion du Budget</h5>
            <small class="">Politique de gestion du budget</small>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.currency"
              rules="required"
            >
              <b-form-group
                label="Devise monétaire"
                label-for="project.finance.currency"
              >
                <b-form-input
                  id="project.finance.currency"
                  v-model="projectData.finance.data.currency"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                  value="USD"
                />
                <b-form-invalid-feedback>
                  Completer Devise monétaire svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.openingbalance"
              rules="required"
            >
              <b-form-group
                label="Solde d’ouverture depuis la dernière tranche reçue"
                label-for="project.finance.openingbalance"
              >
                <b-form-input
                  id="project.finance.openingbalance"
                  v-model="projectData.finance.data.openingbalance"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Solde d’ouverture depuis la dernière tranche reçue.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.lastinstallmentpaid"
              rules="required"
            >
              <b-form-group
                label="Dernière tranche versée"
                label-for="project.finance.lastinstallmentpaid"
              >
                <b-form-input
                  id="project.finance.lastinstallmentpaid"
                  v-model="projectData.finance.data.lastinstallmentpaid"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Dernière tranche versée svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.fundavailableforperiod"
              rules="required"
            >
              <b-form-group
                label="Total des fonds disponibles pour la période"
                label-for="project.finance.fundavailableforperiod"
              >
                <b-form-input
                  id="project.finance.fundavailableforperiod"
                  v-model="projectData.finance.data.fundavailableforperiod"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Total des fonds disponibles pour la période svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.amountexpenseforperiod"
              rules="required"
            >
              <b-form-group
                label="Montant total des dépenses pour la période"
                label-for="project.finance.amountexpenseforperiod"
              >
                <b-form-input
                  id="project.finance.amountexpenseforperiod"
                  v-model="projectData.finance.data.amountexpenseforperiod"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Montant total des dépenses pour la période svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.closingbalance"
              rules="required"
            >
              <b-form-group
                label="Solde de clôture"
                label-for="project.finance.closingbalance"
              >
                <b-form-input
                  id="project.finance.closingbalance"
                  v-model="projectData.finance.data.closingbalance"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Solde de clôture svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.expenserate"
              rules="required"
            >
              <b-form-group
                label="Taux des dépenses depuis le début du projet (total dépenses/total décaissement"
                label-for="project.finance.expenserate"
              >
                <b-form-input
                  id="project.finance.expenserate"
                  v-model="projectData.finance.data.expenserate"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Taux des dépenses depuis le début du projet (total
                  dépenses/total décaissement svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="project.finance.periodicalexpenserate"
              rules="required"
            >
              <b-form-group
                label="Taux des dépenses pour la période (total des dépenses/total des fonds disponibles pour la période)"
                label-for="project.finance.periodicalexpenserate"
              >
                <b-form-input
                  id="project.finance.periodicalexpenserate"
                  v-model="projectData.finance.data.periodicalexpenserate"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Taux des dépenses pour la période (total des
                  dépenses/total des fonds disponibles pour la période) svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Outcome -->
      <tab-content :title="projectData.outcome.name">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-primary">{{ projectData.outcome.name }}</h5>
            <small class="">{{ projectData.outcome.description }}</small>
          </b-col>
        </b-row>

        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="addNewOutcome"
        >
          <!-- Form Loop -->
          <b-row
            v-for="(outcome, outcome_index) in projectData.outcome.data"
            :id="outcome_index"
            :key="outcome_index"
            ref="row"
          >
            <!-- Name -->
            <b-col md="9">
              <b-form-group>
                <label
                  style="color: #007bff"
                  :for="`projectData.outcome.data.` + outcome_index + `.name`"
                >
                  Designation pour :
                  {{ `Résultat Intermédiaire ` + (outcome_index+1)  }}
                </label>
                <b-form-input
                  :id="`projectData.outcome.data.` + outcome_index + `.name`"
                  v-model="projectData.outcome.data[outcome_index].name"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <!-- Cost -->
            <!-- <b-col md="2">
              <b-form-group label="Cost" label-for="cost">
                <b-form-input id="cost" type="number" placeholder="32" />
              </b-form-group>
            </b-col> -->

            <!-- Quantity -->
            <!-- <b-col md="2">
              <b-form-group label="Quantity" label-for="quantity">
                <b-form-input id="quantity" type="number" placeholder="1" />
              </b-form-group>
            </b-col> -->

            <!-- Profession -->
            <!-- <b-col lg="2" md="1">
              <b-form-group label="Price" label-for="price">
                <b-form-input id="pzrice" value="32$" plaintext />
              </b-form-group>
            </b-col> -->

            <!-- Remove Button -->
            <b-col lg="2" md="3" class="mb-10">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeOutcome(outcome_index)"
              >
                <feather-icon icon="XIcon" class="mr-10" />
                <span>Rétirer</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>
        </b-form>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="btn-sm"
          @click="addNewOutcome"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Ajouter Résultat Intermédiraire</span>
        </b-button>
      </tab-content>

      <!-- Output -->
      <tab-content :title="projectData.outcome.output.name">
        <!-- step 1 - output loop -->
        <app-collapse
          accordion
          :type="collapseType"
          v-for="(outcome, outcome_index) in projectData.outcome.data"
          :id="outcome.id + `B` + outcome_index"
          :key="outcome.id + `B` + outcome_index"
          ref="app-collapse-item"
        >
          <app-collapse-item :title="outcome.code">
            <b-form
              ref="form2"
              v-if="projectData.outcome.data[outcome_index].output.length > 0"
              :style="{ height: trHeight }"
              class="repeater-form"
              @submit.prevent="addNewOutcomeOutput(outcome_index)"
              style="padding: 0px; margin: 0px"
            >
              <!-- Outcome Form Loop -->
              <b-row
                v-for="(output, output_index) in projectData.outcome.data[
                  outcome_index
                ].output"
                :id="outcome_index + output.id + `C` + output_index"
                :key="outcome_index + output.id + `C` + output_index"
                ref="row2"
              >
                <!-- Name -->
                <b-col md="9">
                  <b-form-group>
                    <label
                      style="color: #17a2b8"
                      :for="
                        `projectData.outcome.output.data.` +
                        output_index +
                        `.name`
                      "
                    >
                      Designation pour :
                      {{
                        projectData.outcome.data[outcome_index].output[
                          output_index
                        ].code
                      }}
                    </label>

                    <b-form-input
                      :id="
                        `projectData.outcome.output.data.` +
                        output_index +
                        `.name`
                      "
                      v-model="
                        projectData.outcome.data[outcome_index].output[
                          output_index
                        ].name
                      "
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col lg="3" md="3" class="mb-10">
                  <b-button
                    v-ripple.500="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    style="width: auto"
                    @click="removeOutcomeOutput(outcome_index, output_index)"
                  >
                    <feather-icon icon="XIcon" />
                    <span>Rétirer</span>
                  </b-button>
                </b-col>
                <!-- <b-col cols="12">
                  <hr />
                </b-col> -->
              </b-row>
            </b-form>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-info"
              class="btn-sm"
              @click="addNewOutcomeOutput(outcome_index)"
            >
              <feather-icon icon="PlusIcon" />
              <span>Ajouter Résultat Immédiat</span>
            </b-button>
          </app-collapse-item>
        </app-collapse>
      </tab-content>

      <!-- activity -->
      <tab-content :title="projectData.outcome.output.activity.name">
        <b-row>
          <b-col md="4" class="mb-2">
            <h5 class="mb-0" style="color: #b11fdb">
              {{ projectData.outcome.output.activity.name }}
            </h5>
            <small class="">{{
              projectData.outcome.output.activity.description
            }}</small>
          </b-col>
        </b-row>

        <app-collapse accordion :type="collapseType" ref="app-collapse-item">
          <!-- step 1 - activity loop -->
          <div
            v-for="(outcome, outcome_index) in projectData.outcome.data"
            :id="outcome.id + `N` + outcome_index"
            :key="outcome.id + `N` + outcome_index"
          >
            <div
              v-for="(output, output_index) in projectData.outcome.data[
                outcome_index
              ].output"
              :id="output_index + output.id + `E` + outcome_index"
              :key="output_index+ output.id + `E` + outcome_index"
            >
              <app-collapse-item :title="output.code">
                <b-form
                  :ref="`form3` + outcome.id + output.id"
                  :style="{ height: trHeight }"
                  class="repeater-form"
                  @submit.prevent="
                    addNewOutcomeOutputActivity(outcome_index, output_index)
                  "
                  style="padding: 0px; margin: 0px"
                >
                  <!-- Activity Loop -->
                  <b-row
                    v-for="(activity, activity_index) in projectData.outcome
                      .data[outcome_index].output[output_index].activity"
                    :id="output_index + output.id + `E` + outcome_index + activity.id + `EF` + activity_index"
                    :key="output_index + output.id + `E` + outcome_index + activity.id + `EF` + activity_index"
                    ref="row3"
                  >
                    <!-- Name -->
                    <b-col md="9">
                      <b-form-group>
                        <label
                          style="color: #17a2b8"
                          :for="
                            `projectData.outcome.data.output.activity.` +
                            activity_index +
                            `.name`
                          "
                        >
                          Designation pour:
                          {{
                            projectData.outcome.data[outcome_index].output[
                              output_index
                            ].activity[activity_index].code
                          }}
                        </label>

                        <b-form-input
                          :id="
                            `projectData.outcome.data.output.activity.` +
                            activity_index +
                            `.name`
                          "
                          v-model="
                            projectData.outcome.data[outcome_index].output[
                              output_index
                            ].activity[activity_index].name
                          "
                          type="text"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col lg="3" md="3" class="mb-10">
                      <b-button
                        v-ripple.500="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        style="width: auto"
                        @click="
                          removeOutcomeOutputActivity(
                            outcome_index,
                            output_index,
                            activity_index
                          )
                        "
                      >
                        <feather-icon icon="XIcon" />
                        <span>Rétirer</span>
                      </b-button>
                    </b-col>
                    <!-- <b-col cols="12">
                  <hr />
                </b-col> -->
                  </b-row>
                </b-form>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-info"
                  class="btn-sm"
                  @click="addNewOutcomeOutputActivity(outcome_index, output_index)"
                >
                  <feather-icon icon="PlusIcon" />
                  <span>Ajouter Activité</span>
                </b-button>
              </app-collapse-item>
            </div>
          </div>
        </app-collapse>
      </tab-content>

      <!-- sub activity -->
      <tab-content
        :title="projectData.outcome.output.activity.sub_activity.name"
      >
        <b-row>
          <b-col md="12" class="mb-2">
            <h5 class="mb-0" style="color: #b11fdb">
              {{ projectData.outcome.output.activity.sub_activity.name }}
            </h5>
            <small class="">{{
              projectData.outcome.output.activity.sub_activity.description
            }}</small>
          </b-col>
        </b-row>

        <app-collapse accordion :type="collapseType" ref="app-collapse-item">
          <!-- step 1 - sub activity loop -->
          <div
            v-for="(outcome, outcome_index) in projectData.outcome.data"
            :id="outcome.id + `F` + outcome_index"
            :key="outcome.id + `F` + outcome_index"
          >
            <div
              v-for="(output, output_index) in projectData.outcome.data[
                outcome_index
              ].output"
              :id="outcome_index + output.id + `O` + output_index"
              :key="outcome_index + output.id + `O` + output_index"
            >
              <div
                v-for="(activity, activity_index) in projectData.outcome.data[
                  outcome_index
                ].output[output_index].activity"
                :id="output_index + outcome_index + activity.id + `G` + activity_index"
                :key="output_index + outcome_index + activity.id + `G` + activity_index"
              >
                <app-collapse-item :title="activity.code">
                  <b-form
                    ref="form4"
                    :style="{ height: trHeight }"
                    class="repeater-form"
                    @submit.prevent="
                      addNewOutcomeOutputActivitySubActivity(
                        outcome_index,
                        output_index,
                        activity_index
                      )
                    "
                    style="padding: 0px; margin: 0px"
                  >
                    <!-- Sub Activity Loop -->
                    <b-row
                      v-for="(sub_activity, sub_activity_index) in projectData
                        .outcome.data[outcome_index].output[output_index]
                        .activity[activity_index].sub_activity" 
                        :id="outcome_index + `.` + output_index + `.` + activity_index + `G` + sub_activity_index"
                      :key="outcome_index + `.` + output_index + `.` + activity_index + `G` + sub_activity_index"
                      ref="row4"
                    >
                      <!-- Name -->
                      <b-col md="9">
                        <b-form-group>
                          <label
                            style="color: #17a2b8"
                            :for="
                              `projectData.outcome.data.output.activity.` +
                              activity_index +
                              `.sub.` +
                              sub_activity_index +
                              `.name`
                            "
                          >
                            Designation pour:
                            {{
                              projectData.outcome.data[outcome_index].output[
                                output_index
                              ].activity[activity_index].sub_activity[
                                sub_activity_index
                              ].code
                            }}
                          </label>

                          <b-form-input
                            :id="
                              `projectData.outcome.data.output.activity.` +
                              activity_index +
                              `.sub.` +
                              sub_activity_index +
                              `.name`
                            "
                            v-model="
                              projectData.outcome.data[outcome_index].output[
                                output_index
                              ].activity[activity_index].sub_activity[
                                sub_activity_index
                              ].name
                            "
                            type="text"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col lg="3" md="3" class="mb-10">
                        <b-button
                          v-ripple.500="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger"
                          class="mt-0 mt-md-2"
                          style="width: auto"
                          @click="
                            removeOutcomeOutputActivitySubActivity(
                              outcome_index,
                              output_index,
                              activity_index,
                              sub_activity_index
                            )
                          "
                        >
                          <feather-icon icon="XIcon" />
                          <span>Rétirer</span>
                        </b-button>
                      </b-col>
                      <!-- <b-col cols="12">
                    <hr />
                  </b-col> -->
                    </b-row>
                  </b-form>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-info"
                    class="btn-sm"
                    @click="
                      addNewOutcomeOutputActivitySubActivity(
                        outcome_index,
                        output_index,
                        activity_index
                      )
                    "
                  >
                    <feather-icon icon="PlusIcon" />
                    <span>Ajouter Sous-Activité</span>
                  </b-button>
                </app-collapse-item>
              </div>
            </div>
          </div>
        </app-collapse>
      </tab-content>

      <!-- budget track -->
      <tab-content :title="projectData.budget.name">
        <b-row>
          <b-col md="12" class="mb-2">
            <h5 class="mb-0" style="color: #b11fdb">
              {{ projectData.budget.name }}
            </h5>
            <small class="">{{ projectData.budget.description }}</small>
          </b-col>
        </b-row>

        <table>
          <thead>
            <tr>
              <th scope="col" rowspan="2">Activités</th>
              <th scope="col" rowspan="2">Sous-Activités</th>
              <th scope="col" rowspan="2">Partie Responsable</th>
              <th scope="col" rowspan="2">Catégorie Budgétaire</th>
              <th scope="col" rowspan="2">Description des postes Budgétaire</th>
              <th scope="col" :colspan="years.length + 2">Montant démandé</th>
              <th scope="col" rowspan="2">Contribut° du Candidat</th>
              <th scope="col" rowspan="2">Budget Total</th>
            </tr>
            <tr>
              <th
                v-for="(year, year_index) in years"
                :key="year.text"
                scope="col"
              >
                {{ year.text }}
              </th>
              <th scope="col">Tot.</th>
              <th scope="col">%</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(outcome, outcome_index) in projectData.outcome.data"
              :id="outcome.id"
            >
              <template
                v-for="(output, output_index) in projectData.outcome.data[
                  outcome_index
                ].output"
                :id="output.id"
              >
                  
                <template
                  v-for="(activity, activity_index) in projectData.outcome.data[
                    outcome_index
                  ].output[output_index].activity"
                  :id="
                    outcome_index +
                    `.` +
                    outcome.id +
                    `.` +
                    output.id +
                    `.` +
                    activity.id +
                    `M`
                  "
                >
                  <tr>
                    <td :rowspan="projectData.outcome.data[
                      outcome_index
                      ].output[output_index].activity[activity_index].sub_activity.length + 1"> 
                      {{ activity.code }}
                    </td>
                  </tr>

                  <tr
                  v-for="(sub_activity, sub_activity_index) in projectData.outcome.data[
                    outcome_index
                  ].output[output_index].activity[activity_index].sub_activity"
                  :id="
                  outcome_index +
                    `.` +
                    outcome_index +
                    `.` +
                    output_index +
                    `.` +
                    activity_index +
                    `SS` + sub_activity_index"
                  :key="outcome_index +
                    `.` +
                    outcome_index +
                    `.` +
                    output_index +
                    `.` +
                    activity_index +
                    `SS` + sub_activity_index"
                >
                  
                  <td>
                    {{ sub_activity.code }}
                  </td>
                  <td>
                    <b-form-input
                      :id="
                        `projectData.outcome.data.output.activity.` +
                        activity_index + `.` + sub_activity_index +
                        `.management_part`
                      "
                      v-model="
                        projectData.outcome.data[outcome_index].output[
                          output_index
                        ].activity[activity_index].sub_activity[sub_activity_index].management_part
                      "
                      type="text"
                      size="sm"
                    />
                  </td>
                  <td>
                    <b-form-input
                      :id="
                        `projectData.outcome.data.output.activity.` +
                         activity_index + `.` + sub_activity_index +
                        `.budget_category`
                      "
                      v-model="
                        projectData.outcome.data[outcome_index].output[
                          output_index
                        ].activity[activity_index].sub_activity[sub_activity_index].budget_category
                      "
                      type="text"
                      size="sm"
                    />
                  </td>
                  <td>
                    <b-form-input
                      :id="
                        `projectData.outcome.data.output.activity.` +
                        activity_index + `.` + sub_activity_index + 
                        `.budget_post_description`
                      "
                      v-model="
                        projectData.outcome.data[outcome_index].output[
                          output_index
                        ].activity[activity_index].sub_activity[sub_activity_index].budget_post_description
                      "
                      type="text"
                      size="sm"
                    />
                  </td>

                  <td v-for="(year, year_index) in years" :key="year.text + `Y`">
                    <b-form-input
                      v-model="
                        projectData.outcome.data[outcome_index]
                          .output[output_index].activity[activity_index].sub_activity[sub_activity_index]
                          .amount[year.value]
                      "
                      type="text"
                      size="sm"
                    />
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    -
                  </td>
                  <td>
                    <b-form-input
                      v-model="
                        projectData.outcome.data[outcome_index]
                          .output[output_index].activity[activity_index].sub_activity[sub_activity_index]
                          .contibution[sub_activity_index]
                      "
                      type="text"
                      size="sm"
                    />
                  </td>
                  <td>
                    -
                  </td>
                </tr>
                </template>
              </template>
            </template>
          </tbody>
        </table>
      </tab-content>

      <!-- Rapportage -->
      <tab-content title="Rapportage">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Social Links</h5>
            <small class="text-muted">Enter Your Social Links</small>
          </b-col>
          <b-col md="6">
            <b-form-group label="Twitter" label-for="v-twitter">
              <b-form-input
                id="v-twitter"
                placeholder="https://twitter.com/abc"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Facebook" label-for="v-facebook">
              <b-form-input
                id="v-facebook"
                placeholder="https://facebook.com/abc"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Google+" label-for="v-google-plus">
              <b-form-input
                id="v-google-plus"
                placeholder="https://plus.google.com/abc"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="LinkedIn" label-for="v-linked-in">
              <b-form-input
                id="v-linked-in"
                placeholder="https://linkedin.com/abc"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- Outils de Gestion -->
      <tab-content title="Outils de Gestion">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0 text-success">
              {{ projectData.managementtool.name }}
            </h5>
            <small class="">{{ projectData.managementtool.description }}</small>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="managementtool.data.donor"
              rules="required"
            >
              <b-form-group
                label="Outils de gestion programmatique"
                label-for="managementtool.data.donor"
              >
                <b-form-input
                  id="managementtool.data.donor"
                  v-model="projectData.managementtool.data.donor"
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Outils de gestion programmatique svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <validation-provider
              #default="validationContext"
              name="managementtool.data.programaticmanagement"
              rules="required"
            >
              <b-form-group
                label="Outils de gestion programmatique"
                label-for="programaticmanagement"
              >
                <b-form-input
                  id="managementtool.data.programaticmanagement"
                  v-model="
                    projectData.managementtool.data.programaticmanagement
                  "
                  :state="getValidationState(validationContext)"
                  trim
                  size="sm"
                />
                <b-form-invalid-feedback>
                  Completer Outils de gestion programmatique svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { $themeConfig } from "@themeConfig";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

// wizard
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { codeIconInfo } from "./code";

// sweet alert
import { codeBasic } from "../../extensions/sweet-alert/code";
// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";
// accordions
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { BFormRadioGroup, BFormRadio } from "bootstrap-vue";
import { codeCollapseTypes } from "./code";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "project";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormDatepicker,
    flatPickr,
    vSelect,
    Logo,
    // wizard
    FormWizard,
    TabContent,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
    // accordion
    AppCollapse,
    AppCollapseItem,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      imageActionText: "",
      actionIndex: 0,
      reduction: 0,
      subTotal: 0,
      total: 0,
      currency: "$US",
      showed: true,
      loading: false,
      selectedContry: "",
      selectedLanguage: "",
      name: "",
      emailValue: "",
      PasswordValue: "",
      passwordCon: "",
      first_name: "",
      last_name: "",
      address: "",
      landMark: "",
      pincode: "",
      twitterUrl: "",
      facebookUrl: "",
      googleUrl: "",
      linkedinUrl: "",
      city: "",
      required,
      email,
      codeIcon: "",
      years: [],
      semesters: [],
      trimesters: [],
      months: [],
      executiontimetypeUnit: [
        { value: "mono-year", text: "Annuel" },
        { value: "multi-year", text: "Pluriannuel" },
      ],
      workplantimingUnit: [
        { value: "annual", text: "Annuelle" },
        { value: "semester", text: "Semestrielle" },
        { value: "trimester", text: "Trimestrielle" },
        { value: "month", text: "Mensuelle" },
      ],
      annualsubdivisionUnit: [
        { value: "semester", text: "Semestre" },
        { value: "trimester", text: "Trimestre" },
        { value: "month", text: "Mois" },
      ],
      durationUnit: [
        // { value: "hour", text: "Heure" },
        // { value: "day", text: "Jour" },
        // { value: "week", text: "Semaine" },
        { value: "year", text: "Annéé" },
        { value: "month", text: "Mois" },
      ],
      countryName: [
        { value: "select_value", text: "Select Value" },
        { value: "Russia", text: "Russia" },
        { value: "Canada", text: "Canada" },
        { value: "China", text: "China" },
        { value: "United States", text: "United States" },
        { value: "Brazil", text: "Brazil" },
        { value: "Australia", text: "Australia" },
        { value: "India", text: "India" },
      ],
      languageName: [
        { value: "nothing_selected", text: "Nothing Selected" },
        { value: "English", text: "English" },
        { value: "Chinese", text: "Mandarin Chinese" },
        { value: "Hindi", text: "Hindi" },
        { value: "Spanish", text: "Spanish" },
        { value: "Arabic", text: "Arabic" },
        { value: "Malay", text: "Malay" },
        { value: "Russian", text: "Russian" },
      ],
      // form repeater
      items: [
        {
          id: 1,
          selected: "male",
          selected1: "designer",
          prevHeight: 0,
        },
      ],
      nextTodoId: 2,
      // accordion
      collapseType: "default",
      codeCollapseTypes,
    };
  },
  props: {
    projectData: {
      type: Object,
    },
    stepformblankData: {
      type: Object,
    },
    itemformblankData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  mounted() {
    // console
    if (router.currentRoute.name == "project-add-new") {
      this.operationTitle = "PARAMETRAGE DU NOUVEAU ";
      this.operationIcon = "AddIcon";
    }
    if (router.currentRoute.name == "project-view") {
      this.operationTitle = "Détails de la ";
      this.operationIcon = "EyeIcon";
    }
    if (router.currentRoute.name == "project-edit") {
      this.operationTitle = "Modification de la ";
      this.operationIcon = "EditIcon";
    }

    if (router.currentRoute.name == moduleName + "-add-new") {
      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter Image";
      this.actionIndex = 1;
      this.showed = true;

      this.$props.projectData.steps = [];
      this.$props.projectData.prefix = {
        year: "Année ",
        semester: "Semestre ",
        trimester: "Trimestre",
        month: "M",
        outcome: "Résultat Intermédiaire ",
        output: "Résultat Immédiat ",
        activity: "Activité ",
        sub_activity: "Sous-Activité ",
      };
      
      this.$props.projectData.steps.push(
        JSON.parse(JSON.stringify(this.$props.stepformblankData))
      );
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }
    if (router.currentRoute.name == moduleName + "-edit") {
      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier Image";
      this.actionIndex = 3;
      this.showed = true;
    }

    // form repeater
    this.initTrHeight();
    this.initTrHeight2();

    // temporisator
    setTimeout(() => {
      this.summaryUpdate();
    }, 1500);
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
    window.addEventListener("resize", this.initTrHeight2);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
    window.removeEventListener("resize", this.initTrHeight2);
  },
  methods: {
    formSubmitted() {

      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Form Submitted",
          icon: "EditIcon",
          variant: "success",
        },
      });

    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    reinitForm() {
      //
      this.$props.projectData.name = "";
      this.$props.projectData.type = "";
      this.$props.projectData.time = "";
      this.$props.projectData.time_unit = "";
      // remove all occurences
      this.$props.projectData.steps = [];
      // initialise 1 element
      this.$props.projectData.steps.push(
        JSON.parse(JSON.stringify(this.$props.stepformblankData))
      );
      // updqte / calculate summary
      this.summaryUpdate();
      // update row heigth
      this.initTrHeight();
      this.initTrHeight2();
    },
    formReadyForSubmission() {
      // updqte / calculate summary
      this.summaryUpdate();

      // validation check condition
      this.errors = [];
      if (this.total <= 0) {
        this.errors.push("Sélectionner le(s) Intrants(s) svp.");
      }
      if (this.checkFormInputs("name") == false) {
        this.errors.push("Compléter le nom de l'instrant.");
      }
      if (this.errors.length > 0) {
        // console.log(this.errors);
        return false;
      } else {
        return true;
      }
    },
    simulate(index, val) {
      var simulateQty = 0;
      simulateQty = this.$refs["simulate_item_qty_" + index][0].$el._value;
      var simulateCost = parseFloat(0);
      simulateCost = parseFloat(
        this.$refs["simulate_item_cost_" + index][0].$el._value
      );
      ///////////////////////////////////////////////////////////////
      if (simulateQty > 1) {
        // simulate average
        var costAverrage = simulateCost / simulateQty;
        this.$props.projectData.items[index].cost = costAverrage;
      } else {
        // same value
        this.$props.projectData.items[index].cost = simulateCost;
        this.$props.projectData.items[index].qty = 1;
      }
      // update sumary
      this.summaryUpdate();
    },
    applySimulation() {
      // apply
      // console.log(this.$refs);
    },
    executiontimetypeChange(newValue) {
      console.log("executiontimetypeChange" + newValue);
    },
    workplantimingChange(newValue) {
      console.log("workplantimingChange" + newValue);
    },
    annualsubdivisionChange(newValue) {},
    projectdurationunitChange(newValue) {
      // recuperation de l'année 
      //
      var projectduration = this.projectData.project.data.projectduration;
      if (projectduration >= 1) {
        switch (this.projectData.project.data.projectdurationunit.value) {
          case "year":
            // initialize
            // year loop
            this.years = [];
            for (var i = 1; i <= projectduration; i++) {
              this.years.push({
                text: this.projectData.prefix.year + i,
                value: this.projectData.prefix.year + i,
                semesters: [
                  {
                    text: this.projectData.prefix.semester + i,
                    value: this.projectData.prefix.semester + i,
                  },
                  {
                    text: this.projectData.prefix.semester + (i + 1),
                    value: this.projectData.prefix.semester + (i + 1),
                  },
                ],
              });
            }
            // semester loop
            this.semesters = [];
            for (var i = 1; i <= this.years.length * 2; i++) {
              this.semesters.push({
                text: this.projectData.prefix.semester + i,
                value: this.projectData.prefix.semester + i,
              });
            }
            console.log("this.semesters");
            console.log(this.semesters);

            // trimester loop
            this.trimesters = [];
            var trimesters_count = this.semesters.length * 2;
            for (var i = 1; i <= trimesters_count; i++) {
              this.trimesters.push({
                text: this.projectData.prefix.trimester + i,
                value: this.projectData.prefix.trimester + i,
              });
            }
            // month loop
            this.months = [];
            var months_count = this.trimesters.length * 3;
            for (var i = 1; i <= months_count; i++) {
              this.months.push({
                text: this.projectData.prefix.month + i,
                value: this.projectData.prefix.month + i,
              });
            }
          case "month":
            // initialize
            this.months = [];
            for (var i = 1; i <= projectduration; i++) {
              this.months.push({
                text: this.projectData.prefix.month + i,
                value: this.projectData.prefix.month + i,
              });
            }
          default:
          //
          // console.log("No choice");
        }
      } else {
        // console.log(this.years);
        alert("Saisissez d'abord la durée du projet");
      }
      

      //
      console.log(this.projectData.outcome.data);

    },
    async addUpdateItemAction() {
      //////////////////////////////////////////////////////////////
      let formData = new FormData();

      // if (this.formReadyForSubmission() == false) {
      //   console.log(this.refFormObserver.fields);
      //   return;
      // }

      //   alert("ok");
      //   return;

      const defaultActionText = this.actionName;

      let url = "";
      if (router.currentRoute.name == "project-add-new") {
        this.actionName = "Enregistrement...";
        url = $themeConfig.app.api_endpoint + "add_" + moduleName;
      }
      if (router.currentRoute.name == "project-view") {
        url = $themeConfig.app.api_endpoint + "view_" + moduleName;
      }
      if (router.currentRoute.name == "project-edit") {
        this.actionName = "Modification...";
        url = $themeConfig.app.api_endpoint + "update_" + moduleName;
        // convert bool to int
      }

      // finalise form data
      // delete this.$props.moduleData.image_url;
      formData = this.$props.projectData;

      await myApi
        .post(moduleName, formData)
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "project-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          console.log(error);

          this.actionName = defaultActionText;
          // 
          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    checkFormInputs() {
      var errors = 0;
      for (const [s_key, s_value] of Object.entries(
        this.$props.projectData.steps
      )) {
        // steps
        for (const [i_key, i_value] of Object.entries(
          this.$props.projectData.steps[s_key].items
        )) {
          // items
          // console.log(i_value);

          if (i_value.name.name.length > 0) {
            // value is not null
          } else {
            errors++;
          }
        }
      }
      if (errors > 0) {
        return false;
      } else {
        return true;
      }
    },
    calculateSummary() {
      var subTot = parseFloat(0);
      // step for
      // //
      for (const [step_key, step_value] of Object.entries(
        this.$props.projectData.steps
      )) {
        if (
          this.$props.projectData.steps[step_key].items != undefined &&
          this.$props.projectData.steps[step_key].items != null
        ) {
          // value //
          // item for //
          for (const [key, value] of Object.entries(
            this.$props.projectData.steps[step_key].items
          )) {
            subTot = (subTot + parseFloat(value.cost)) * value.qty;
          }
        } else {
          // null value
        }
      }
      return subTot;
    },
    goToPrecItem() {
      router.push({ name: "project-view" });
    },
    goToNextItem() {
      router.push({ name: "project-list" });
    },
    goToList() {
      router.push({ name: "project-list" });
    },
    summaryUpdate() {
      // update summary
      this.subTotal = this.calculateSummary();
      this.total = this.subTotal; //  - (this.subTotal * this.reduction) / 100;
    },
    addNewStepForm() {
      // this.$refs.form[0].style.overflow = "hidden";
      this.$props.projectData.steps.push(
        JSON.parse(JSON.stringify(this.$props.stepformblankData))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          // this.$refs.form[0].style.overflow = null;
        }, 350);
      });
    },
    addNewItemInItemForm(step_index) {
      // this.$refs.form[0].style.overflow = "hidden";
      this.$props.projectData.steps[step_index].items.push(
        JSON.parse(JSON.stringify(this.$props.itemformblankData))
      );

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          // this.$refs.form[0].style.overflow = null;
        }, 350);
      });
    },
    updateItemForm(s_index, index, val) {
      const { cost, qty, buy_unit, description } = val;
      // ---------------------------------------------------------
      this.$props.projectData.steps[s_index].items[index].cost = cost;
      this.$props.projectData.steps[s_index].items[index].qty_unit = buy_unit;
      if (
        this.$props.projectData.steps[s_index].items[index].qty == null ||
        this.$props.projectData.steps[s_index].items[index].qty == undefined
      ) {
        // default qty
        this.$props.projectData.steps[s_index].items[index].qty = 1;
      }
      this.$props.projectData.steps[s_index].items[index].description =
        description;

      // update cart
      this.summaryUpdate();
    },
    // removeOutcome(s_index, index) {
    //   this.$props.projectData.steps[s_index].items.splice(index, 1);
    //   this.trTrimHeight(this.$refs.row[0].offsetHeight);

    //   // update summary
    //   this.summaryUpdate(1);
    // },
    initTrHeight() {
      this.trSetHeight(null);
      this.trSetHeight2(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form[0].scrollHeight);
      });
    },
    addNewOutcome() {
      var counterOutcome = this.projectData.outcome.data.length + 1;

      this.projectData.outcome.data.push({
        id: "",
        code: this.projectData.prefix.outcome + counterOutcome,
        name: "",
        output: [],
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    addNewOutcomeOutput(outcomeINDEX) {
      var counterOutcome = outcomeINDEX + 1;
      var counterOutput = this.projectData.outcome.data[outcomeINDEX].output.length + 1;

      this.projectData.outcome.data[outcomeINDEX].output.push({
        id: "",
        code: this.projectData.prefix.output  + counterOutcome + "." + counterOutput,
        name: "",
        activity: [],
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row2[0].offsetHeight);
      });
    },
    addNewOutcomeOutputActivity(outcomeINDEX, outputINDEX) {
      var counterOutcome = outcomeINDEX+1;
      var counterOutput = outputINDEX+1;
      var counterActivity = this.projectData.outcome.data[outcomeINDEX].output[
        outputINDEX
      ].activity.length + 1;

      this.projectData.outcome.data[outcomeINDEX].output[
        outputINDEX
      ].activity.push({
        id: "",
        code: this.projectData.prefix.activity  + counterOutcome + "." + counterOutput +  "." + counterActivity,
        name: "",
        sub_activity: [],
        years: this.years,
      });
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row2[0].offsetHeight);
      });
    },
    addNewOutcomeOutputActivitySubActivity(outcomeINDEX, outputINDEX, activityINDEX) {
      var counterOutcome = outcomeINDEX+1;
      var counterOutput = outputINDEX+1;
      var counterActivity = activityINDEX+1;
      var counterSubActivity = this.projectData.outcome.data[outcomeINDEX].output[outputINDEX].activity[
        activityINDEX
      ].sub_activity.length + 1; 

      this.projectData.outcome.data[outcomeINDEX].output[outputINDEX].activity[
        activityINDEX
      ].sub_activity.push({
        id: "",
        code: this.projectData.prefix.sub_activity  + counterOutcome + "." + counterOutput +  "." + counterActivity +  "." + counterSubActivity,
        name: "",
        years: this.years,
        amount: [],
        contibution: [],
        budget: "",
      }); 
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row2[0].offsetHeight);
      });
    },
    removeOutcome(index) {
      this.projectData.outcome.data.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    removeOutcomeOutput(outcome_index, output_index) {
      this.projectData.outcome.data[outcome_index].output.splice(
        output_index,
        1
      );
      this.trTrimHeight(this.$refs.row2[0].offsetHeight);
    },
    removeOutcomeOutputActivity(outcome_index, output_index, activity_index) {
      this.projectData.outcome.data[outcome_index].output[
        output_index
      ].activity.splice(activity_index, 1);
      this.trTrimHeight(this.$refs.row2[0].offsetHeight);
    },
    removeOutcomeOutputActivitySubActivity(
      outcome_index,
      output_index,
      activity_index,
      sub_activity_index
    ) {
      this.projectData.outcome.data[outcome_index].output[
        output_index
      ].activity[activity_index].sub_activity.splice(sub_activity_index, 1);
      this.trTrimHeight(this.$refs.row2[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.trSetHeight2(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
        this.trSetHeight2(this.$refs.form2.scrollHeight);
      });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = "app-project";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetmoduleData = () => {
      this.$props.projectData = this.$props.moduleData;
    };

    let { refFormObserver, getValidationState, resetForm } =
      formValidation(resetmoduleData);
    
    let typeOptions = [
      { label: "Matière Première", value: "first-way" },
      { label: "Produit Semi-Fini", value: "half-finished" },
      { label: "Produit Fini", value: "finished" },
      { label: "Produit Dérivé", value: "derived" },
      { label: "Consommables", value: "consummable" },
      { label: "Service", value: "service" },
      { label: "Autres", value: "other" },
    ];

    let timeUnitsOptions = [];

    timeUnitsOptions.push({ label: "Tierce(s)", value: "Tick" });
    timeUnitsOptions.push({ label: "Seconde(s)", value: "Sec" });
    timeUnitsOptions.push({ label: "Minute(s)", value: "Min" });
    timeUnitsOptions.push({ label: "Heure(s)", value: "Hour" });
    timeUnitsOptions.push({ label: "Jour(s)", value: "Day" });
    timeUnitsOptions.push({ label: "Semaine(s)", value: "Week" });
    timeUnitsOptions.push({ label: "Mois(s)", value: "Month" });
    timeUnitsOptions.push({ label: "Année(s)", value: "Year" });

    const unitsOptions = [];
    unitsOptions.push({ label: "Unité(s)", value: "Unité(s)" });
    unitsOptions.push({ label: "Element(s)", value: "Element(s)" });
    unitsOptions.push({ label: "Service(s)", value: "Service(s)" });
    unitsOptions.push({ label: "Piece(s)", value: "Piece(s)" });
    unitsOptions.push({ label: "Packet(s)", value: "Packet(s)" });
    unitsOptions.push({ label: "Carton(s)", value: "Carton(s)" });
    unitsOptions.push({ label: "Mesure(s)", value: "Mesure(s)" });

    unitsOptions.push({ label: "Gramme(s)", value: "Gr" });
    unitsOptions.push({ label: "Kilo-gramme(s)", value: "Kg" });
    unitsOptions.push({ label: "Tonne(s)", value: "Tn" });

    unitsOptions.push({ label: "Mili-litre(s)", value: "Ml" });
    unitsOptions.push({ label: "Litre(s)", value: "Lt" });
    unitsOptions.push({ label: "Metre-Cube(s)", value: "M3" });
    unitsOptions.push({ label: "Mili-metre(s)", value: "Mm" });
    unitsOptions.push({ label: "Metre(s)", value: "Mt" });
    unitsOptions.push({ label: "Km(s)", value: "Km" });
    unitsOptions.push({ label: "Metre-Carré(s)", value: "M2" });
    unitsOptions.push({ label: "Are(s)", value: "Ar" });
    unitsOptions.push({ label: "Hectare(s)", value: "Ha" });
    unitsOptions.push({ label: "Bidon(s)", value: "Bid" });
    unitsOptions.push({ label: "Bouteille(s)", value: "Bout" });

    // if (props.requirementData != null && props.requirementData != undefined) {
    //   for (const [key, value] of Object.entries(props.requirementData.units)) {
    //     unitsOptions.push({ label: value.name, value: value.symbol });
    //   }
    // }

    const paymentMethods = ["Bank Account", "PayPal", "UPI Transfer"];

    return {
      paymentMethods,
      refFormObserver,
      getValidationState,
      resetForm,
      typeOptions,
      unitsOptions,
      timeUnitsOptions,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.text-purple {
  color: #800080;
  text-color: #800080;
}
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
  border-right: 1px solid #ccc;
  font-size: 0.5em;
}

table th {
  font-size: 0.5em;
  /* letter-spacing: 0.1em; text-transform: uppercase; */
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    text-align: right;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.5em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: right;
    /*font-weight: bold; */
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>
